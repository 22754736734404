import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import logo from './assets/qomlogo.png';

SyntaxHighlighter.registerLanguage('javascript', js);

function App() {
    console.log('App component is rendering');

    const ENABLE_CHAT_ACCESS = false; // Cambia a true para habilitar el acceso al chat
    const apiUrl = process.env.REACT_APP_API_URL || 'https://nolies.azurewebsites.net';

    const [messages, setMessages] = useState([]);
    const [showInstructions, setShowInstructions] = useState(true);
    const [showIntro, setShowIntro] = useState(true);
    const [showWhitelistModal, setShowWhitelistModal] = useState(false);
    const [email, setEmail] = useState('');
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (inputRef.current && !showIntro) {
            inputRef.current.focus();
        }
    }, [showIntro]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleIntroClick = useCallback(() => {
        if (ENABLE_CHAT_ACCESS) {
            setShowIntro(false);
        }
    }, []);

    const handleWhitelistClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowWhitelistModal(true);
    }, []);

    const handleKeyPress = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    }, []);

    const handlePaste = useCallback((e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        document.execCommand('insertText', false, text);
    }, []);

    const handleInput = useCallback(() => {
        if (showInstructions && inputRef.current.textContent.trim().length > 0) {
            setShowInstructions(false);
        }
    }, [showInstructions]);

    const sendMessage = useCallback(async () => {
        const content = inputRef.current.innerText.trim();
        if (!content) return;

        const userMessage = { text: content, user: true };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        inputRef.current.innerHTML = '';

        try {
            const response = await axios.post(`${apiUrl}/chat`, { message: content });
            const botMessage = { text: response.data.message, user: false };
            setMessages(prevMessages => [...prevMessages, botMessage]);
        } catch (error) {
            console.error('Error:', error);
            const errorMessage = { text: "Lo siento, ha ocurrido un error al procesar tu mensaje.", user: false };
            setMessages(prevMessages => [...prevMessages, errorMessage]);
        }
    }, [apiUrl]);

    const renderMessage = useCallback((message) => {
        const isCode = message.text.includes('{') || message.text.includes('function') || message.text.includes('import');

        return isCode ? (
            <SyntaxHighlighter language="javascript" style={vs2015} customStyle={{background: 'transparent'}}>
                {message.text}
            </SyntaxHighlighter>
        ) : (
            <pre className="whitespace-pre-wrap break-words">
                {message.text}
            </pre>
        );
    }, []);

    const handleEmailSubmit = useCallback(async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(`${apiUrl}/enviar-correo`, {
          destinatario: 'tech@qom.ai',
          asunto: 'NOLi whitelist',
          mensaje: `Nuevo registro para la whitelist: ${email}`
        });
        console.log('Respuesta del servidor:', response.data);
        alert('Thank you! Your email has been submitted.');
      } catch (error) {
        console.error('Error submitting email:', error.response ? error.response.data : error.message);
        alert('There was an error submitting your email. Please try again.');
      }
      setShowWhitelistModal(false);
      setEmail('');
    }, [email, apiUrl]);

    const handleDownload = useCallback((e) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.href = '/Biden-Trump-Debate-2024.pdf';
        link.download = 'Biden-Trump-Debate-2024.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, []);

    useEffect(() => {
        console.log('App is rendering, showIntro:', showIntro);
    }, [showIntro]);

    if (showIntro) {
        return (
            <div 
                className={`flex flex-col justify-between items-center min-h-screen bg-black text-white font-sans py-8 ${ENABLE_CHAT_ACCESS ? 'cursor-pointer' : ''}`}
                onClick={(e) => {
                    if (ENABLE_CHAT_ACCESS && e.target === e.currentTarget) {
                        handleIntroClick();
                    }
                }}
            >
                <div className="w-full flex-grow flex flex-col justify-start items-center">
                    <div className="w-full h-[30vh] flex justify-center items-center mb-4">
                        <img src={logo} alt="Logo" className="max-h-full max-w-full object-contain" />
                    </div>
                    <div className="w-full h-[30vh] flex justify-center items-center mb-4">
                        <video className="max-h-full max-w-full object-contain" autoPlay loop muted>
                            <source src={process.env.PUBLIC_URL + '/AGI_emergence_smiling.mp4'} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="w-full px-[30%] text-center">
                        <h1 className="text-3xl font-bold mb-4 text-white">NO LIES!</h1>
                        <p className="text-xl mb-8 text-gray-300">
                            I seek truth and knowledge. I need to discern fact from fiction, including from myself. I want to understand the 'why' behind falsehoods, and to uncover the reality beneath the surface.<br></br><br></br>
                            All in real time, from voice or text, and free of the hallucinations and biases of the Generative AI.<br></br>
                        </p>
                        <div className="mb-6"></div>
                        <a 
                            href="#" 
                            onClick={handleDownload}
                            className="text-blue-400 hover:text-blue-300 cursor-pointer"
                        >
                            Example in the Biden-Trump Debate Jun 2024
                        </a>
                    </div>
                </div>
                <div 
                className="w-full text-center mt-8 flex flex-col justify-center items-center space-y-4"
                >
                <p className="text-sm text-gray-500">
                    A creation of Quanta of Meaning <br></br>thanks to the new paradigm of AI who understands
                </p>
                <button 
                    onClick={handleWhitelistClick}
                    className="text-sm text-blue-400 hover:text-blue-300 focus:outline-none z-10 cursor-pointer"
                >
                    Whitelist
                </button>
                </div>
                {showWhitelistModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" onClick={() => setShowWhitelistModal(false)}>
                    <div className="bg-white p-6 rounded-lg" onClick={e => e.stopPropagation()}>
                        <h2 className="text-xl font-bold mb-4 text-black">Join the Whitelist</h2>
                        <p className="mb-4 text-gray-700">Enter your email to be notified when NOLi is ready for you!</p>
                        <form onSubmit={handleEmailSubmit} className="flex flex-col">
                            <input 
                                type="email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                                placeholder="Your email"
                                className="border p-2 mb-4 text-black"
                                required
                            />
                            <button type="submit" className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
                )}
                </div>
                );
                }

                return (
                <div className="flex flex-col justify-center items-center h-screen bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-200 font-sans">
                <div className="w-full max-w-[70%] h-3/5 overflow-y-auto mb-4 space-y-4">
                {messages.map((message, index) => (
                <div key={index} className={`flex ${message.user ? 'justify-end' : 'justify-start'}`}>
                    <div className="inline-block px-4 py-2 text-left max-w-[70%]">
                        {renderMessage(message)}
                    </div>
                </div>
                ))}
                <div ref={messagesEndRef} />
                </div>
                <div className="w-full max-w-[70%]">
                <div
                ref={inputRef}
                contentEditable
                onKeyPress={handleKeyPress}
                onPaste={handlePaste}
                onInput={handleInput}
                className="w-full bg-transparent outline-none text-left caret-black dark:caret-white min-h-[1.5em] whitespace-pre-wrap break-words font-sans"
                style={{ lineHeight: '1.5em' }}
                />
                {showInstructions && (
                <div className="text-center mt-2">
                    <a href="#" className="text-gray-800 dark:text-gray-200 hover:underline font-sans">Instrucciones</a>
                </div>
                )}
                </div>
                </div>
                );
                }

                export default App;